* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: sans-serif;
}
.containers {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* CHECKBOX CHECKMARK */

  /* Create a custom checkbox */
.checkmarks {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid hsl(186, 15%, 59%);
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  
  /* When the checkbox is checked, add a blue background */
  .containers input:checked ~ .checkmarks {
    background-color: rgb(81, 255, 0);
    border: 1px solid hsl(135, 82%, 27%);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmarks:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containers input:checked ~ .checkmarks:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containers .checkmarks:after {
    left: 9px;
    top: 7px;
    width: 6px;
    height: 8px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /* Toggle switch */
  
.switch {
	position: relative;
	display: inline-block;
	width: 45px;
	height: 24px;
  margin-left: 8px;
  border: 1px solid rgb(34, 149, 8);
	border-radius: 34px;
	}

	.switch input { 
	opacity: 0;
	width: 0;
	height: 0;
	}

	.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: .4s;
	transition: .4s; 
	}

	.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 3.2px;
	bottom: 2.5px;
	background-color: rgb(34, 149, 8);
	-webkit-transition: .4s;
	transition: .4s;
	}
	

	input:checked + .slider {
    background: hsl(236, 72%, 84%);
	}

	input:focus + .slider {
	box-shadow: 0 0 1px rgb(34, 149, 8);
	}

	input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(19px);
	}

	.slider.round {
	border-radius: 34px;
	}

	.slider.round:before {
	border-radius: 50%;
	}



  body {
    background-color: hsl(190, 27%, 96%);
  }
  .link {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    background-color: rgb(34, 149, 8);
    display: inline-block;
    text-align: center;
  }

  /* Accreditials & login page */
  section.all {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 40%;
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 20px;
    border: 1px solid hsl(186, 15%, 59%);
  }
  .login {
    margin-top: 7px;
  }
  section.all > div.log, section.all > section > div.log {
    background-color: rgb(34, 149, 8); 
    padding: 10px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  section.all > div > h1, section.all> section > div.log > h1 {
    width: 95%;
    margin: auto;
    color: #fff;
    font-weight: 700;
  }
  section.all form {
    background-color: #fff;
  }
  section.all form div {
    width: 95%;
    margin: auto;
    margin-top: 20px;
  }
  section.all form div input, .link, #dropdown {
    width: 100%;
    padding: 9px 0px;
    border-radius: 3px;
    border: none;
  }
  section.all form div input {
    border: 1px solid hsl(186, 15%, 59%);
    padding-left: 10px;
    margin-top: 7px;
  }
  section.all form div input:focus {
    outline: none;
    box-shadow: 0 0 3px 3px rgb(34, 149, 8);
    border: 1px solid hsl(186, 15%, 59%);
  }

  /* Position page */
  section.position {
    background-color: unset;
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    width: 70%;
    padding-bottom: unset;
    border: unset;
    margin: auto;
    margin-top: 30px;
  }
  section.position > section {
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid hsl(186, 15%, 59%);
    border-radius: 5px;
    padding-bottom: 20px;
  }
  section.position > section > p {
    text-align: center;
    margin-top: 30px;
  }
  div.choose {
    display: flex;
    align-items: center;
    width: 95%;
    margin: auto;
    margin-top: 30px;
  }
  div.choose img {
    margin: 0px 20px;
  }
  section.result {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  section.result h1 {
    width: 20%;
    font-size: 1rem;
  }
  div.another {
    width: 100%;
  }
  div.choose article p:nth-child(1) {
    font-weight: 700;
    margin-bottom: 5px;
  }
  .img {
    border-radius: 5px;
  }
  section.all > section div:nth-child(3) {
    width: 95%;
    margin: auto;
    margin-top: 30px;
  }
  section.all > section div:nth-child(3) p {
    font-weight: 700;
  }
  section.all > section div:nth-child(3) article {
    border: 1px solid hsl(186, 15%, 59%);
    margin-top: 5px;
    padding: 15px 0px;
    padding-left: 20px;
    border-radius: 5px;
  }
  section.all > section div:nth-child(3) article span {
    margin-right: 5px;
  }
  section.all > section div:nth-child(3) article span.num {
    background-color: rgb(34, 149, 8);
    padding: 3px 8px;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
  }
  section.all > section div:nth-child(3) article span.req {
    color: rgb(218, 28, 28);
    font-size: .9rem;
  }
  hr {
    margin-top: 20px;
  }
  div.div {
    width: 70%;
    margin: auto;
    background-color: #fff;
    padding: 17px 0px;
    border: 1px solid hsl(186, 15%, 59%);
    border-radius: 5px;
    margin-bottom: 80px;
  }
  .vote {
    display: block;
    width: 95%;
    margin: auto;
  }
  .title {
    text-align: center;
    margin-top: 50px;
    font-size: 2rem;
  }
  .sent {
    text-align: center;
  }
  .sent img {
    width: 30%;
  }
  .sent h1 {
    font-size: 2rem;
  }
  .sent p {
    margin: 5px 0px;
  }

  /* Admin dashboard */
  .welcome {
    display: flex;
  }
  .welcome nav {
    width: 200px; 
    height: 100vh; 
    padding: 10px; 
    background: #fff;
    position: sticky;
    top: 0px;
  }
  .welcome nav ul {
    list-style-type: none; 
    margin-top: 15px;
  }
  .welcome nav ul li .side {
    text-decoration: none;
    display: block;
    color: black;
  }
  .welcome nav ul hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  div.begin {
    margin-left: 20px;
    padding: 10px;
  }
  .welcome div h1 {
    margin-top: 15px;
  }
  .start {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  /* Posts page */
  section.posts {
    margin: auto;
    
  }
  section.posts article {
    margin-top: 15px;
    margin-left: 20px;
  }
  section.posts article h1 {
    text-decoration: underline;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  section.posts article ul {
    list-style-type: none;
  }
  section.posts article ul li {
    margin-bottom: 10px;
  }
  section.posts article ul .add {
    text-decoration: none;
    background-color: #eee;
    color: black;
    padding: 5px;
    font-size: .9rem;
    border: 1px solid black;
  }

  /* Add Contestant Page */
  #dropdown {
    border: 1px solid hsl(186, 15%, 59%);
    padding-left: 10px;
    margin-top: 7px;
  }
  #dropdown:focus {
    outline: none;
    box-shadow: 0 0 3px 3px rgba(0, 123, 255, 0.5);
  }
  .not {
    font-size: .8rem;
    margin-top: 7px;
  }

  .contest {
    width: 100%;
  }
  .contest div.addcon {
    margin: auto;
    margin-bottom: 80px;
    width: 70%;
  }
  .third {
    width: 70%;
  }
  section div.position {
    margin-left: unset;
  }

  .result:last-of-type + .divider {
    display: none;
}


table, td, th {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 25px;
	}
  section.table {
    margin: 20px 10px;
    border-radius: 5px;
    margin-left: 25px;
  }
  section.table > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  section.table > div h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  .active {
    text-decoration: none;
    background-color: #eee;
    color: black;
    padding: 5px;
    font-size: .9rem;
    border: 1px solid black;
    margin: 5px;
  }
  section.table > div .active {
    margin: unset;
  }

  .btn {
    display: inline-block;
  }
  @media screen and (max-width: 600px) {
    section.all, div.div {
      width: 90%;
    }
  }


/* Preloader */

.loader-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.771);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}
.loader-container > div {
    text-align: center;
}
.loader-container > div p {
    font-weight: 600;
    color: #fff;
}
.rotator {
	width: 40px;
	height: 40px;
	border: 5px solid rgb(34, 149, 8);
	border-right-color: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	animation: rotate 0.4s infinite;
    display: inline-block;
	/* position: absolute;
	top: 45%;
	right: 50%; */
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}